<template>
  <div class="home-page d-flex flex-column">
    <v-container style="height: 400px;">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col cols="6">
          <v-progress-linear
            color="#FFCC00"
            label="Progress"
            title="Progress"
            indeterminate
            rounded
            height="6"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import i18n from '../helpers/i18n.js'

export default {
  head () {
    return {
      title: i18n.global.t('meta_title.home'),
      meta: [
        { property: 'og:title', content: i18n.global.t('meta_title.home') },
        { property: 'og:description', content: i18n.global.t('meta_desc.home') },
        {
          hid: 'description',
          name: 'description',
          content: i18n.global.t('meta_desc.home')
        }
      ]
    }
  }
}
</script>
<style scoped>
  .home-page{
    background: black;
  }
</style>
